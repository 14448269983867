import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "coding-basics"
    }}>{`Coding Basics`}</h1>
    <p>{`Please follow the following coding basics for any language:`}</p>
    <h2 {...{
      "id": "brackets-in-an-if--else"
    }}>{`Brackets in an if / else`}</h2>
    <p>{`The body of an if or else must be encapsulated it increases readability and decreases the introduction of bugs.`}</p>
    <p>{`For single-line statements a ternary is preferred.`}</p>
    <h5 {...{
      "id": "example"
    }}>{`Example:`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (a < b)
  test(c)
load(b)

if (a < b)
  //test(c)
load(b)
`}</code></pre>
    <p>{`Commenting out one line for debugging purposes resulted in the addition of a new bug, this can easily be avoid by using curly braces:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (a < b) {
  test(c)
}
load(b)

if (a < b) {
  //test(c)
}
load(b)
`}</code></pre>
    <h2 {...{
      "id": "using-string-quotes"
    }}>{`Using string quotes`}</h2>
    <p>{`In most programming languages several options are available for the quoting of text, in the following examples Javascript style quoting is used which is applicable to a variety of other programming languages.`}</p>
    <p>{`For the following examples the key question is: can the readbility be improved to make the code more simple and concise?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`text = "The quick brown fox jumped over the lazy dog"
`}</code></pre>
    <p>{`Although the code is correct the double quotes have have no additional function, the following code is more concise by using single quotes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`text = 'The quick brown fox jumped over the lazy dog'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`text = "The quick brown \\"fox\\" jumped over the lazy dog"
`}</code></pre>
    <p>{`The code is correct, but readability is improved by switching to single quotes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`text = 'The quick brown "fox" jumped over the lazy dog'
`}</code></pre>
    <h2 {...{
      "id": "tabs-for-indentation"
    }}>{`Tabs for indentation`}</h2>
    <p>{`Tabs will never be used for indentation. Period. `}</p>
    <h2 {...{
      "id": "spaces-for-indentation"
    }}>{`Spaces for indentation`}</h2>
    <p>{`Spaces will always be used for indentation. Period.`}</p>
    <p>{`On that note, please make sure you setup your linter and IDE for the following standards:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Language`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spaces`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Javascript / TypeScript`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 Spaces`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`C#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 Spaces`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Java / Kotlin`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 Spaces`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Go`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 Spaces`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Python`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 Spaces`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      